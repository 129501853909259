<template>
  <div class="client-rewrite">
    <ClientRewriteComponent />
    <div id="fadeLayer"></div>
    <div class="loading">
      <vue-loading
        id="loading"
        type="spin"
        color="#333"
        :size="{ width: '50px', height: '50px' }"
      ></vue-loading>
    </div>
  </div>
</template>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-app.js"></script>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-storage.js"></script>
<script type="module">
import ClientRewriteComponent from "@/components/Client/ClientRewriteComponent.vue";
import { VueLoading } from "vue-loading-template";
import AuthStateCheckMixin from "../../utility/authStateCheck";

export default {
  name: "ClientRewrite",
  components: {
    ClientRewriteComponent,
    VueLoading,
    AuthStateCheckMixin,
  },
  mounted() {
    this.authStateCheck(this.$store, this.$router);
  },
  data: function () {
    return {
      temporaryData: [],
    };
  },
  mixins: [AuthStateCheckMixin],
};
</script>
<style scoped>
#fadeLayer {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.5;
  z-index: 999999999;
  display: none;
}

#loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 80%;
  height: 3.2rem;
  display: none;
}
</style>