<template>
  <div class="container">
    <div class="row">
      <div class="subject col-12">クライアント編集</div>
    </div>
    <div class="row client_id">
      <div class="col-2">クライアントID</div>
      <div class="col-1">
        {{
          this.$store.state.clients[this.$store.state.selectedClient].client_id
        }}
      </div>
    </div>
    <div class="row client_name">
      <div class="col-2">クライアント名</div>
      <div class="col-1">
        <input
          style="width: 403px"
          type="text"
          id="client-name"
          name="client_name"
          :value="
            this.$store.state.clients[this.$store.state.selectedClient]
              .client_name
          "
        />
      </div>
    </div>
    <p id="client-name-alert">※クライアント名を入力してください</p>
    <div class="row event_priod">
      <div class="col-2">契約期間</div>
      <div class="col-1 contract_start_day">
        <input
          style="width: 170px"
          type="date"
          id="contract-start-day"
          name="client_name"
          :value="
            this.$store.state.clients[
              this.$store.state.selectedClient
            ].contract_start_day
              .toString()
              .replace(/\//g, '-')
          "
        />
      </div>
      <div class="wavy_line col-1"><span>〜</span></div>
      <div class="col-1 contract_finished_day">
        <input
          style="width: 170px"
          type="date"
          id="contract-finished-day"
          name="client_name"
          :value="
            this.$store.state.clients[
              this.$store.state.selectedClient
            ].contract_finished_day
              .toString()
              .replace(/\//g, '-')
          "
        />
      </div>
    </div>
    <p id="contract-period-alert">※期間を選択してください</p>
    <div class="row event_priod">
      <div class="col-2">プラン</div>
      <div class="col-4">
        <label>
          <input
            type="radio"
            class="radio_button"
            name="plan"
            value="Limit50DBプラン"
            style="transform: scale(2)"
          /><span>Limit50DBプラン</span>
        </label>
        <label>
          <input
            type="radio"
            class="radio_button"
            name="plan"
            value="Limit100DBプラン"
            style="transform: scale(2); margin-top: 16px"
          /><span>Limit100DBプラン</span>
        </label>
        <label>
          <input
            type="radio"
            class="radio_button"
            name="plan"
            value="Limit500DBプラン"
            style="transform: scale(2); margin-top: 16px"
          /><span>Limit500DBプラン</span>
        </label>
        <label>
          <input
            type="radio"
            class="radio_button"
            name="plan"
            value="manual"
            style="transform: scale(2); margin-top: 16px"
          /><span style="margin-right: 3%; margin-left: 7%">Limit</span
          ><input
            :value="
              this.$store.state.clients[this.$store.state.selectedClient]
                .contract_plan
            "
            type="text"
            id="manualInput"
            name="manualInput"
            minlength="4"
            maxlength="8"
            size="10"
          /><span style="margin-left: 3%">DBプラン</span>
        </label>
      </div>
    </div>
    <p id="db-plan-alert">※プランを入力してください</p>
    <div class="row event_priod">
      <div class="col-2">TikQ連携</div>
      <div class="tikq col-4">
        <div class="tikq-wrap">
          <label>
            <input
              type="radio"
              class="radio_button"
              name="tikq"
              value="あり"
              style="transform: scale(2)"
            /><span class="tikq-select">あり</span>
          </label>
        </div>
        <div class="tikq-wrap">
          <label>
            <input
              type="radio"
              class="radio_button"
              name="tikq"
              value="なし"
              style="transform: scale(2)"
            /><span class="tikq-select">なし</span>
          </label>
        </div>
      </div>
    </div>
    <div class="row client_name">
      <div class="col-2">メールアドレス</div>
      <div class="col-1" id="mail">
        {{ this.$store.state.clients[this.$store.state.selectedClient].mail }}
      </div>
    </div>
    <div class="row client_name">
      <div class="col-2">パスワード</div>
      <div class="col-1" id="password">
        {{
          this.$store.state.clients[this.$store.state.selectedClient].password
        }}
      </div>
    </div>
    <div class="row client_name">
      <div class="col-2">管理者名(マスター)</div>
      <div class="col-1">
        <input
          style="width: 403px"
          type="text"
          id="maneger"
          name="maneger"
          :value="
            this.$store.state.clients[this.$store.state.selectedClient].maneger
          "
        />
      </div>
    </div>
    <p id="maneger-alert">※管理者名を入力してください</p>
    <div class="row">
      <div class="col-2"></div>
      <div class="col-1 reserve-button-div">
        <button class="reserve-button" type="submit" v-on:click="show()">
          保存
        </button>
      </div>
      <div class="col-1 cancel-button-div">
        <button
          class="cancel-button"
          type="submit"
          v-on:click="changeClientListHome()"
        >
          キャンセル
        </button>
      </div>
    </div>
    <div id="easyModal" class="modal">
      <div class="modal-content">
        <div class="modal-body">
          <div class="text-content">
            <p class="reserve-text">内容を変更します。</p>
            <p class="reserve-text">本当によろしいですか？</p>
            <div class="btn-modal">
              <div>
                <button
                  class="modal-cancel-button modalClose"
                  type="submit"
                  v-on:click="hide()"
                >
                  いいえ
                </button>
              </div>
              <div>
                <button
                  class="modal-reserve-button"
                  type="submit"
                  v-on:click="rewrite()"
                >
                  はい
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="https://cdn.jsdelivr.net/npm/vue-js-modal@1.3.31/dist/index.min.js"></script> 
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-app.js"></script>
<script src="https://www.gstatic.com/firebasejs/7.2.0/firebase-auth.js"></script>
<script src="https://www.gstatic.com/firebasejs/8.6.2/firebase-storage.js"></script>
 <script type="module">
</script>
<script>
export default {
  name: "ClientRewriteComponent",
  data: function () {
    return {
      client_name: "",
      contract_start_day: "",
      contract_finished_day: "",
      contract_plan: "",
      checkValue: "",
      client_id: "",
      mail: "",
      password: "",
      maneger: "",
      tikqAlignment: "",
    };
  },
  mounted() {
    this.authStateCheck();
    document.body.className = "login";
    document.querySelector("body").style.background = "#F8F8F8";
    this.$root.headerHide = true;
    this.$root.footerHide = true;

    var dbPlan =
      "Limit" +
      this.$store.state.clients[this.$store.state.selectedClient]
        .contract_plan +
      "DBプラン";
    let contaractPalnElements = document.getElementsByName("plan");
    let len = contaractPalnElements.length;

    //TikQ連携のラジオボックスの値をセットする
    this.setTikQAlignmentValue();
    for (let i = 0; i < len; i++) {
      if (dbPlan == contaractPalnElements.item(i).value) {
        contaractPalnElements.item(i).checked = true;
        return;
      } else {
        if (
          dbPlan == "Limit50DBプラン" ||
          dbPlan == "Limit100DBプラン" ||
          dbPlan == "Limit500DBプラン"
        ) {
          continue;
        } else {
          contaractPalnElements.item(i).checked = true;
          document.getElementById("manualInput").value = dbPlan.replace(
            /DBプラン|Limit/g,
            ""
          );
          this.contract_plan = dbPlan.replace(/DBプラン|Limit/g, "");
        }
      }
    }
  },
  computed: {
    clientName: {
      get() {
        return this.$store.state.clients[this.$store.state.selectedClient];
      },
      set(value) {
        this.$store.commit("setRewriteClientName", value);
      },
    },
    contractStartDay: {
      get() {
        var changeFormatContractStartDay = this.$store.state.clients[
          this.$store.state.selectedClient
        ].contract_start_day
          .toString()
          .replace(/\//g, "-");
        return changeFormatContractStartDay;
      },
      set() {},
    },
  },
  beforeDestroy() {
    document.body.className = "";
    this.$root.headerHide = false;
    this.$root.footerHide = false;
  },
  methods: {
    ////////////////////////////////////////////////
    /////
    /////説明
    /////TikQラジオボタンの選択値を取得しデータに保存する
    /////
    /////
    /////
    /////
    ////////////////
    /////////////////////
    ////////////////////////////
    ////////////////////////////////////////////////
    getTikqRadioButton: function () {
      //名前に「tikq」と付いた要素を取得
      let tikQRadioButtonElements = document.getElementsByName("tikq");

      //取得した要素の数
      let len = tikQRadioButtonElements.length;
      for (let i = 0; i < len; i++) {
        if (tikQRadioButtonElements.item(i).checked) {
          this.tikqAlignment = tikQRadioButtonElements.item(i).value;
          break;
        }
      }
    },
    /**
     * TikQ連携の値をセットする
     */
    setTikQAlignmentValue: function () {
      //登録されていたTikQ連携の値を取得
      var selectedTikQAlignment =
        this.$store.state.clients[this.$store.state.selectedClient]
          .tikQ_alignment;

      //TikQ連携のラジオボタンの要素を取得する
      let selectedTikQAlignmentElements = document.getElementsByName("tikq");
      let len = selectedTikQAlignmentElements.length;
      for (let i = 0; i < len; i++) {
        if (
          selectedTikQAlignment == selectedTikQAlignmentElements.item(i).value
        ) {
          selectedTikQAlignmentElements.item(i).checked = true;
          return;
        }
      }
    },
    /////////////////////////////////////
    //////////////
    ////////説明
    ////////ログイン状態を確認
    ///
    ///引数：なし
    ///////////////////////
    ////////////////////////////////////////////////////
    authStateCheck() {
      var sessionLen = sessionStorage.length;
      var sessionKey;
      for (var i = 0; i < sessionLen; i++) {
        sessionKey = sessionStorage.key(i);
        if (sessionKey.indexOf("firebase:authUser") != -1) {
          sessionKey = true;
          break;
        } else {
          sessionKey = false;
        }
      }
      if (sessionKey == false) {
        this.$store.commit("resetUser");
        this.$router.push({ path: "/" });
      }
    },
    emitEvent() {
      this.$emit("child-emit");
    },
    show: function () {
      if (this.checkValidate() == true) {
        //モーダル表示
        document.getElementById("easyModal").style.display = "block";
      } else {
      }
    },
    hide: function () {
      //モーダル非表示
      document.getElementById("easyModal").style.display = "none";
    },
    changeClientListHome: function () {
      //キャンセルボタンが押された際に呼び出される
      this.$router.push("/clientManager");
    },
    ///////////////////////////////////
    /////////////
    //////////説明
    //////////編集
    ///
    ///引数:なし
    /////////////////////////////////////////////////
    rewrite: async function () {
      //アカウントが属するクライアントIDリストを取得する
      this.checkSameAccount();
    },
    ///////////////////////////////////
    /////////////
    //////////説明
    //////////未入力のチェック
    ///
    ///引数:なし
    /////////////////////////////////////////////////
    checkValidate() {
      let contaractPalnElements = document.getElementsByName("plan");
      let len = contaractPalnElements.length;

      //自分でDBプランを決めた際に値に代入される
      let manualValue = document.getElementById("manualInput").value;

      //自分でDBプランを選択した際、値が入っているかどうかの真偽値が入る
      var manualValueCheck = true;

      for (let i = 0; i < len; i++) {
        if (contaractPalnElements.item(i).checked) {
          this.checkValue = contaractPalnElements.item(i).value;
          if (this.checkValue == "manual") {
            this.contract_plan = manualValue;
            if (this.contract_plan == "") {
              manualValueCheck = false;
            } else {
              manualValueCheck = true;
            }
            this.checkValue = this.contract_plan + "DBプラン";
            break;
          }
          break;
        }
      }

      //dataにクライアント名の値を格納
      this.client_name = document.getElementById("client-name").value;

      //dataに契約期間のスタート日を入れる
      this.contract_start_day =
        document.getElementById("contract-start-day").value;

      //dataに契約期間の終了日を入れる
      this.contract_finished_day = document.getElementById(
        "contract-finished-day"
      ).value;

      //dataに管理者名を格納する
      this.maneger = document.getElementById("maneger").value;

      if (
        this.client_name != "" &&
        this.contract_start_day != "" &&
        this.contract_finished_day != "" &&
        this.checkValue != "" &&
        this.maneger != "" &&
        manualValueCheck == true
      ) {
        document.getElementById("client-name-alert").style.display = "none";
        document.getElementById("contract-period-alert").style.display = "none";
        document.getElementById("maneger-alert").style.display = "none";
        document.getElementById("db-plan-alert").style.display = "none";
        return true;
      } else {
        if (this.client_name == "") {
          document.getElementById("client-name-alert").style.display = "block";
        } else {
          document.getElementById("client-name-alert").style.display = "none";
        }
        if (this.contract_start_day == "" || this.contract_finished_day == "") {
          document.getElementById("contract-period-alert").style.display =
            "block";
        } else {
          document.getElementById("contract-period-alert").style.display =
            "none";
        }
        if (this.maneger == "") {
          document.getElementById("maneger-alert").style.display = "block";
        } else {
          document.getElementById("maneger-alert").style.display = "none";
        }
        if (manualValueCheck == false) {
          document.getElementById("db-plan-alert").style.display = "block";
        } else {
          document.getElementById("db-plan-alert").style.display = "none";
        }
        return false;
      }
    },
    /*
    ///////////////////////////////////
    /////////////
    //////////説明
    //////////firebase storageにクライアント情報を保存する
    ///
    ///引数:なし
    /////////////////////////////////////////////////
    rewriteClientInfo() {
      var instance = this;
      var router = this.$router;
      let contaractPalnElements = document.getElementsByName("plan");
      let len = contaractPalnElements.length;
      for (let i = 0; i < len; i++) {
        if (contaractPalnElements.item(i).checked) {
          this.checkValue = contaractPalnElements
            .item(i)
            .value.replace(/Limit|DBプラン/g, "");
          if (this.checkValue == "manual") {
            this.checkValue = this.contract_plan;
            break;
          }
          break;
        }
      }
      //dataにクライアント名の値を格納
      this.client_name = document.getElementById("client-name").value;

      //TikQ連携のラジオボタンの選択値をインスタンスに登録する
      this.getTikqRadioButton();

      //dataに契約期間のスタート日を入れる
      this.contract_start_day =
        document.getElementById("contract-start-day").value;

      //dataに契約期間の終了日を入れる
      this.contract_finished_day = document.getElementById(
        "contract-finished-day"
      ).value;

      //dataにメールアドレスを格納する
      this.mail = document.getElementById("mail").textContent;

      //dataにパスワードを格納する
      this.password = document.getElementById("password").textContent;

      //dataに管理者名を格納する
      this.maneger = document.getElementById("maneger").value;

      //ローディングを表示する
      this.waitLoading();

      document.getElementById("client-name-alert").style.display = "none";
      document.getElementById("contract-period-alert").style.display = "none";
      document.getElementById("maneger").style.display = "none";
      document.getElementById("db-plan-alert").style.display = "none";
      var changeFormatContractStartDay = this.contract_start_day.replace(
        /\-/g,
        "/"
      );
      var changeFormatContractFinishedDay = this.contract_finished_day.replace(
        /\-/g,
        "/"
      );

      ///////////////////////クライアント一覧にクライアント情報を保存する処理///////////////
      const ref = firebase
        .storage()
        .ref()
        .child(
          "/client/" +
            this.$store.state.clients[this.$store.state.selectedClient]
              .client_id +
            "/" +
            this.$store.state.clients[this.$store.state.selectedClient]
              .seacretId +
            "/" +
            this.$store.state.clients[this.$store.state.selectedClient]
              .client_id +
            ".json"
        );
      const jsonString = JSON.stringify({
        client_id:
          this.$store.state.clients[this.$store.state.selectedClient].client_id,
        seacretId:
          this.$store.state.clients[this.$store.state.selectedClient].seacretId,
        client_name: this.client_name,
        contract_start_day: changeFormatContractStartDay,
        contract_finished_day: changeFormatContractFinishedDay,
        contract_plan: this.checkValue,
        mail: this.mail,
        password: this.password,
        maneger: this.maneger,
        tikQ_alignment: this.tikqAlignment,
      });

      const blob = new Blob([jsonString], { type: "application/json" });
      ref.put(blob).then(function (result) {
        //ローディングを表示する
        instance.downloaded();
        router.push("/clientManager");
      });
    },
    */
    ///////////////////////////////////
    /////////////
    //////////説明
    //////////firebase storageに複数のクライアント情報を編集する
    ///
    ///引数:なし
    /////////////////////////////////////////////////
    rewriteClients: async function (clientIDList) {
      var clientIdCount = 0;
      var instance = this;
      var router = this.$router;
      let contaractPalnElements = document.getElementsByName("plan");
      let len = contaractPalnElements.length;
      for (let i = 0; i < len; i++) {
        if (contaractPalnElements.item(i).checked) {
          this.checkValue = contaractPalnElements
            .item(i)
            .value.replace(/Limit|DBプラン/g, "");
          if (this.checkValue == "manual") {
            this.checkValue = this.contract_plan;
            break;
          }
          break;
        }
      }
      //dataにクライアント名の値を格納
      this.client_name = document.getElementById("client-name").value;

      //dataに契約期間のスタート日を入れる
      this.contract_start_day =
        document.getElementById("contract-start-day").value;

      //dataに契約期間の終了日を入れる
      this.contract_finished_day = document.getElementById(
        "contract-finished-day"
      ).value;

      //dataにメールアドレスを格納する
      this.mail = document.getElementById("mail").textContent;

      //dataにパスワードを格納する
      this.password = document.getElementById("password").textContent;

      //dataに管理者名を格納する
      this.maneger = document.getElementById("maneger").value;

      //TikQ連携のラジオボタンの選択値をインスタンスに登録する
      this.getTikqRadioButton();

      //ローディングを表示する
      this.waitLoading();

      document.getElementById("client-name-alert").style.display = "none";
      document.getElementById("contract-period-alert").style.display = "none";
      document.getElementById("maneger").style.display = "none";
      document.getElementById("db-plan-alert").style.display = "none";
      var changeFormatContractStartDay = this.contract_start_day.replace(
        /\-/g,
        "/"
      );
      var changeFormatContractFinishedDay = this.contract_finished_day.replace(
        /\-/g,
        "/"
      );

      ///////////////////////クライアント一覧にクライアント情報を保存する処理///////////////
      for (var i = 0; i < clientIDList.length; i++) {
        if (
          this.$store.state.clients[this.$store.state.selectedClient]
            .client_id == clientIDList[i]
        ) {
          const ref = firebase
            .storage()
            .ref()
            .child(
              "/client/" +
                clientIDList[i] +
                "/" +
                this.$store.state.clients[this.$store.state.selectedClient]
                  .seacretId +
                "/" +
                clientIDList[i] +
                ".json"
            );
          const jsonString = JSON.stringify({
            client_id:
              this.$store.state.clients[this.$store.state.selectedClient]
                .client_id,
            seacretId:
              this.$store.state.clients[this.$store.state.selectedClient]
                .seacretId,
            client_name: this.client_name,
            contract_start_day: changeFormatContractStartDay,
            contract_finished_day: changeFormatContractFinishedDay,
            contract_plan: this.checkValue,
            mail: this.mail,
            password: this.password,
            maneger: this.maneger,
            used_db:
              this.$store.state.clients[this.$store.state.selectedClient]
                .used_db,
            use_number:
              this.$store.state.clients[this.$store.state.selectedClient]
                .use_number,
            update_date:
              this.$store.state.clients[this.$store.state.selectedClient]
                .update_date,
            tikQ_alignment: this.tikqAlignment,
          });

          const blob = new Blob([jsonString], { type: "application/json" });
          await ref.put(blob).then(function (result) {
            clientIdCount += 1;
            if (clientIdCount == clientIDList.length) {
              instance.rewriteDbFolder();
            }
          });
        } else {
          const ref = firebase
            .storage()
            .ref()
            .child(
              "/client/" +
                clientIDList[i] +
                "/" +
                this.$store.state.clients[i].seacretId +
                "/" +
                clientIDList[i] +
                ".json"
            );
          const jsonString = JSON.stringify({
            client_id: this.$store.state.clients[i].client_id,
            seacretId: this.$store.state.clients[i].seacretId,
            client_name: this.$store.state.clients[i].client_name,
            contract_start_day: this.$store.state.clients[i].contract_start_day,
            contract_finished_day:
              this.$store.state.clients[i].contract_finished_day,
            contract_plan: this.$store.state.clients[i].contract_plan,
            mail: this.$store.state.clients[i].mail,
            password: this.$store.state.clients[i].password,
            maneger: this.maneger,
            used_db: this.$store.state.clients[i].used_db,
            use_number: this.$store.state.clients[i].use_number,
            update_date: this.$store.state.clients[i].update_date,
            tikQ_alignment: this.tikqAlignment,
          });

          const blob = new Blob([jsonString], { type: "application/json" });
          await ref.put(blob).then(async function (result) {
            clientIdCount += 1;
            if (clientIdCount == clientIDList.length) {
              instance.rewriteDbFolder();
            }
          });
        }
      }
    },
    ///////////////////////////////////
    ////////////////////
    ///////////
    /////説明
    //編集するクライアントのアカウントが複数クライアントを管理しているかどうか確認する
    //////////
    ///////////////
    ////////////////////
    ////////////////////////////////////////////////
    checkSameAccount: async function () {
      var instance = this;

      //dataにメールアドレスを格納する
      this.mail =
        this.$store.state.clients[this.$store.state.selectedClient].mail;

      const ref = firebase
        .storage()
        .ref()
        .child("/userBelongClient/" + this.mail.trim() + ".json");

      await ref.getDownloadURL().then(async (url) => {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.onload = function (event) {
          var blob = xhr.response;
          var accountInfno = JSON.parse(blob);
          var belongedClientIdList = [];

          //アカウントが属するグループを取得
          var groups = accountInfno["groups"];
          for (const [key, value] of Object.entries(groups)) {
            belongedClientIdList.push(key);
          }
          //クライアントを編集する
          instance.rewriteClients(belongedClientIdList);
        };
        xhr.send();
      });
    },
    ///////////////////////////////////
    ////////////////////
    ///////////
    /////説明
    //データを取得している間の処理
    //////////
    ///////////////
    ////////////////////
    ////////////////////////////////////////////////
    waitLoading: function () {
      document.getElementById("loading").style.display = "block";
      document.getElementById("fadeLayer").style.display = "block";
    },
    ///////////////////////////////////
    ////////////////////
    ///////////
    /////説明
    //データを取得した後の処理
    //////////
    ///////////////
    ////////////////////
    ////////////////////////////////////////////////
    downloaded: function () {
      document.getElementById("loading").style.display = "none";
      document.getElementById("fadeLayer").style.display = "none";
    },
    ///////////////////////////////////
    ////////////////////
    ///////////
    /////説明
    //DBフォルダーを変更する
    //////////
    ///////////////
    ////////////////////
    ////////////////////////////////////////////////
    rewriteDbFolder: function () {
      //このパスにjsonファイルを格納する
      const ref = firebase
        .storage()
        .ref()
        .child(
          "/db/" +
            this.$store.state.clients[this.$store.state.selectedClient]
              .client_id +
            ".json"
        );
      var instance = this;
      var router = this.$router;

      //このjsonはインフルエンサー投稿フォームで読み取られる
      var clientId = {
        clientId:
          this.$store.state.clients[this.$store.state.selectedClient].client_id,
        contract_plan: this.checkValue,
        used_db:
          this.$store.state.clients[this.$store.state.selectedClient].used_db,
        used_number:
          this.$store.state.clients[this.$store.state.selectedClient]
            .use_number,
      };

      //jsonに変換
      const jsonString = JSON.stringify(clientId);
      const blob = new Blob([jsonString], { type: "application/json" });
      ref.put(blob).then(function (result) {
        //ローディングを非表示にする
        instance.downloaded();
        router.push("/clientManager");
      });
    },
  },
};

//モーダルコンテンツ以外がクリックされた時
addEventListener("click", outsideClose);
function outsideClose(e) {
  if (e.target == document.getElementById("easyModal")) {
    document.getElementById("easyModal").style.display = "none";
  }
}
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500&display=swap");
.client_rewrite_body {
  background-color: #f5f5f5;
}

.container {
  margin-top: 5%;
}

.subject {
  font-size: 32px;
  font-family: "Noto Sans JP", sans-serif;
  margin-left: 0%;
  color: #4a4a4a;
  opacity: 1;
}

.client_id {
  margin-top: 3%;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
}

.client_name {
  margin-top: 24px;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
}

.event_priod {
  margin-top: 3%;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 16px;
}

.radio_button {
  transform: scale(1.5);
}

.tikq {
  display: flex;
}

.tikq-select {
  display: inline-block;
  margin-left: 16px;
}

.tikq-wrap {
  margin-right: 64px;
}

label {
  display: block;
}

span {
  margin-left: 7%;
}

.reserve-button {
  font-family: "Noto Sans JP", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: transparent linear-gradient(180deg, #00979c 0%, #068587 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
  width: 100px;
  height: 40px;
  border: 0;
  color: #ffffff;
}

.reserve-button-div {
  margin-top: 40px;
}
.cancel-button-div {
  margin-top: 40px;
}
@media (min-width: 0px) {
  .cancel-button {
    font-family: "Noto Sans JP", sans-serif;
    text-transform: uppercase;
    outline: 0;
    background: transparent linear-gradient(180deg, #afafaf 0%, #909090 100%) 0%
      0% no-repeat padding-box;
    opacity: 1;
    width: 100px;
    height: 40px;
    border: 0;
    color: #ffffff;
  }
  .cancel-button-div {
    margin-left: 70px;
  }
}
@media (min-width: 700px) {
  .cancel-button {
    font-family: "Noto Sans JP", sans-serif;
    text-transform: uppercase;
    outline: 0;
    background: transparent linear-gradient(180deg, #afafaf 0%, #909090 100%) 0%
      0% no-repeat padding-box;
    opacity: 1;
    width: 100px;
    height: 40px;
    border: 0;
    color: #ffffff;
  }
  .cancel-button-div {
    margin-left: 70px;
  }
}
@media (min-width: 768px) {
  .cancel-button {
    font-family: "Noto Sans JP", sans-serif;
    text-transform: uppercase;
    outline: 0;
    background: transparent linear-gradient(180deg, #afafaf 0%, #909090 100%) 0%
      0% no-repeat padding-box;
    opacity: 1;
    width: 100px;
    height: 40px;
    border: 0;
    color: #ffffff;
  }
  .cancel-button-div {
    margin-left: 50px;
  }
}
@media (min-width: 800px) {
  .cancel-button {
    font-family: "Noto Sans JP", sans-serif;
    text-transform: uppercase;
    outline: 0;
    background: transparent linear-gradient(180deg, #afafaf 0%, #909090 100%) 0%
      0% no-repeat padding-box;
    opacity: 1;
    width: 100px;
    height: 40px;
    border: 0;
    color: #ffffff;
  }
  .cancel-button-div {
    margin-left: 50px;
  }
}
@media (min-width: 900px) {
  .cancel-button {
    font-family: "Noto Sans JP", sans-serif;
    text-transform: uppercase;
    outline: 0;
    background: transparent linear-gradient(180deg, #afafaf 0%, #909090 100%) 0%
      0% no-repeat padding-box;
    opacity: 1;
    width: 100px;
    height: 40px;
    border: 0;
    color: #ffffff;
  }
  .cancel-button-div {
    margin-left: 50px;
  }
}
@media (min-width: 992px) {
  .cancel-button {
    font-family: "Noto Sans JP", sans-serif;
    text-transform: uppercase;
    outline: 0;
    background: transparent linear-gradient(180deg, #afafaf 0%, #909090 100%) 0%
      0% no-repeat padding-box;
    opacity: 1;
    width: 100px;
    height: 40px;
    border: 0;
    color: #ffffff;
  }
  .cancel-button-div {
    margin-left: 30px;
  }
}
@media (min-width: 1100px) {
  .cancel-button {
    font-family: "Noto Sans JP", sans-serif;
    text-transform: uppercase;
    outline: 0;
    background: transparent linear-gradient(180deg, #afafaf 0%, #909090 100%) 0%
      0% no-repeat padding-box;
    opacity: 1;
    width: 100px;
    height: 40px;
    border: 0;
    color: #ffffff;
  }
  .cancel-button-div {
    margin-left: 30px;
  }
}

@media (min-width: 1200px) {
  .cancel-button {
    font-family: "Noto Sans JP", sans-serif;
    text-transform: uppercase;
    outline: 0;
    background: transparent linear-gradient(180deg, #afafaf 0%, #909090 100%) 0%
      0% no-repeat padding-box;
    opacity: 1;
    width: 100px;
    height: 40px;
    border: 0;
    color: #ffffff;
  }
  .cancel-button-div {
    margin-left: 16px;
  }
}

@media (min-width: 1400px) {
  .cancel-button {
    font-family: "Noto Sans JP", sans-serif;
    text-transform: uppercase;
    outline: 0;
    background: transparent linear-gradient(180deg, #afafaf 0%, #909090 100%) 0%
      0% no-repeat padding-box;
    opacity: 1;
    width: 100px;
    height: 40px;
    border: 0;
    color: #ffffff;
  }
  .cancel-button-div {
    margin-left: 16px;
  }
}

@media (min-width: 1200px) {
  .contract_start_day {
    margin-right: 7%;
  }
  .contract_finished_day {
    margin-left: 0.5%;
  }
  .wavy_line {
    margin-top: 0.1%;
    margin-right: 1%;
    margin-left: 1.5%;
    width: 1%;
  }
}

@media (min-width: 1400px) {
  .contract_start_day {
    margin-right: 4.5%;
  }
  .contract_finished_day {
    margin-left: 0.5%;
  }
  .wavy_line {
    margin-top: 0.1%;
    margin-right: 1%;
    margin-left: 1.5%;
    width: 1%;
  }
}

@media (max-width: 1199px) {
  .contract_start_day {
    margin-right: 10%;
  }
  .contract_finished_day {
    margin-left: 1.1%;
  }
  .wavy_line {
    margin-top: 0.1%;
    margin-right: 1%;
    margin-left: 1.5%;
    width: 1%;
  }
}

@media (max-width: 991px) {
  .contract_start_day {
    margin-right: 16%;
  }
  .contract_finished_day {
    margin-left: 2%;
  }
  .wavy_line {
    margin-top: 0.1%;
    margin-right: 1%;
    margin-left: 2%;
    width: 1%;
  }
}

@media (max-width: 767px) {
  .contract_start_day {
    margin-right: 25.5%;
  }
  .contract_finished_day {
    margin-left: 2.3%;
  }
  .wavy_line {
    margin-top: 0.1%;
    margin-right: 1%;
    margin-left: 2%;
    width: 1%;
  }
}
#client-name-alert {
  margin-top: 1%;
  color: red;
  margin-left: 3%;
  font-size: 10px;
  display: none;
}

#maneger-alert {
  margin-top: 1%;
  color: red;
  margin-left: 3%;
  font-size: 10px;
  display: none;
}

#contract-period-alert {
  margin-top: 1%;
  color: red;
  margin-left: 3%;
  font-size: 10px;
  display: none;
}

#db-plan-alert {
  margin-top: 1%;
  color: red;
  margin-left: 3%;
  font-size: 10px;
  display: none;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  background: #1c1c1c 0% 0% no-repeat padding-box;
  opacity: 1;
}

.modal-content {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  color: rgb(0, 0, 0);
  margin: 20% auto;
  width: 30%;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.17);
  animation-name: modalopen;
  animation-duration: 1s;
}

@keyframes modalopen {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.reserve-text {
  font-family: "Noto Sans JP", sans-serif;
  text-align: center;
  font-size: 18px;
  color: #2a2a2a;
  opacity: 1;
  font-size: 24px;
}

.modalClose:hover {
  cursor: pointer;
}

.modal-body {
  padding: 10px 20px;
  color: black;
}

.text-content {
  font-family: "Noto Sans JP", sans-serif;
  margin-top: 10%;
}

.modal-reserve-button {
  font-family: "Noto Sans JP", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: transparent linear-gradient(180deg, #00969b 0%, #06858b 100%) 0%
    0% no-repeat padding-box;
  border: 0.5px solid #a5a5a5;
  opacity: 1;
  width: 100px;
  height: 40px;
  color: #ffffff;
  font-size: 16px;
}

.modal-cancel-button {
  font-family: "Noto Sans JP", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: transparent linear-gradient(180deg, #b1b1b1 0%, #8e8e8e 100%) 0%
    0% no-repeat padding-box;
  border: 0.5px solid #a5a5a5;
  opacity: 1;
  width: 100px;
  height: 40px;
  color: #ffffff;
  font-size: 16px;
}

.btn-modal {
  text-align: center;
  margin-bottom: 5%;
  margin-top: 30px;
}

.btn-modal > div {
  margin-left: 5%;
  margin-right: 5%;
  display: inline-block;
}
</style>
